/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
 
:root {
    --my-account-content-background: #{$white};
    --my-account-wrapper-margin-mobile: 16px;
    --my-account-content-border: 1px solid rgba(10, 9, 3, 0.16);
    --my-account-order-subheading-border: 2px solid #ccc;
    --my-account-order-subheading-color: #333;
}

.MyAccount {
    background: #fafafa;

    @include mobile {
        margin-block-start: 0px;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 0;
        padding-block-start: 12px;
        padding-block-end: 12px;

        @include mobile {
            grid-column-gap: 28px;
            padding: 0;
            grid-template-columns: 1fr;
        }

        @include tablet {
            display: flex;
            flex-direction: column;
            padding-inline: 0;
        }
    }

    &-TabContent {
        background: var(--my-account-content-background);

        &_activeTab {
            &_my-orders {
                .MyAccount {
                    &-Heading {
                        @include desktop {
                            display: flex;
                        }
                    }

                    &-SubHeading {
                        display: block;
                        border-radius: 3px;
                        border: var(--my-account-order-subheading-border);
                        inset-block-end: 4px;
                        color: var(--my-account-order-subheading-color);
                        font-size: 14px;
                        font-weight: 400;
                        margin-block-start: 3px;
                        margin-inline-start: 10px;
                        margin-inline-end: 0;
                        margin-block-end: 0;
                        padding: 5px 10px;
                        line-height: 13px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        @include mobile {
            margin-inline: var(--my-account-wrapper-margin-mobile);
            background: none;
        }

        @include desktop {
            border: none;
            padding: 0;
            background: none;
        }

        @include tablet {
            padding-inline: 32px;
        }
    }

    &-Heading {
        text-transform: none;
        margin: 0;
        margin-block-end: 24px;
        padding-block-end: 15px;
        font-size: 20px;
        border-bottom: 1px solid var(--primary-base-color);

        @include mobile {
            margin-block-end: 10px;
            display: block;
            font-size: 16px;
            padding-block-end: 5px;
        }

        @include tablet {
            margin-block-start: 22px;
        }

        .order_ID_label {
            display: flex;
            width: 100%;
            justify-content: space-between;

            @include mobile {
                gap: 2rem;
            }

            .order_details {
                display: flex;
                gap: 4%;

                @include mobile {
                    font-size: 18px;
                }
            }

            .order_id {
                font-size: 20px;
                color: #707070;
                letter-spacing: 0px;

                @include mobile {
                    font-size: 18px;
                }
            }
        }
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }

    &-TabContent_activeTab_my-account {
        .MyAccount-Heading {
            display: none;
        }
    }

    &-TabContent_activeTab_store-credit {
        .MyAccount-Heading {
            display: none;
        }   
    }

    &-TabContent_activeTab_address {
        .MyAccount-Heading {
            display: none;
        }
    }

    .Footer {
        @include mobile {
            background: var(--footer-background-color);
            padding-block-start: 50px;
        }

        &-Top {
            @include mobile {
                border-top: 4px solid #F7F7F7;
                margin: 0 24px;
            }
        }
    }
}
