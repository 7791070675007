/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountNewsletterSubscription {
    font-size: 14px;

    &-header {
        height: auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        padding: 3rem;

        @include mobile {
            margin-block-end: 2rem;
        }
    }

    &-option {
        .subscribe_text {
            text-align: start;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0px;
            color: #0F0F0F;
            opacity: 1;
        }
    }

    @include desktop {
        display: inline-block;
        width: 100%;
    }

    .FieldForm-Body {
        .FieldForm-Fields {
            padding-block-end: 2rem;
            margin-block-end: 2rem;
            border-bottom: 1px solid #CBCBCB;
        }
    }

    &-Subscribe_button {
        width: 100%;
        justify-content: flex-end;
        display: flex;

        .Button {
            width: 222px;
            height: 40px;
            background: var(--primary-base-color);
            border: 1px solid var(--primary-base-color);
            border-radius: 3px;
            opacity: 1;
            color: #fff;
            text-transform: capitalize;
            font-size: 14px;
            cursor: pointer;

            @include mobile {
                width: 144px;
                height: 36px;
                font-size: 12px;
                cursor: pointer;
            }

            &:hover {
                background-color: #FFFFFF;       
                color: var(--primary-base-color);
                width: 222px;
                height: 40px;
                border: 1px solid var(--primary-base-color);
                border-radius: 3px;
                opacity: 1;
                text-transform: capitalize;
                font-size: 14px;
                cursor: pointer;  
                
                @include mobile {
                    width: 144px;
                    height: 36px;
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}
