/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable indentation */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountMyOrders {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;

    @include mobile {
        grid-gap: 10px;
        margin-block-end: 20px;
    }

    &-Button {
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
        }
    }

    &-Table {
        font-size: 14px;
        width: 100%;
        height: auto;
        background: var(--color-white);
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        max-width: 100%;

        
        @include mobile {
            button.view_button {
                color: var(--primary-base-color);
                font-size: 12px;
                text-decoration: underline;
            }

            button.Re_button {
                color: var(--primary-base-color);
                font-size: 12px;
                text-decoration: underline;
            }
        }

        thead th {
            background-color: white !important; /* stylelint-disable-line declaration-no-important */
            line-height: 23px;
            font-size: 14px;
        }

        tr {
            border-bottom: 1px solid var(--color-black);
        }

        tr:not(:last-child) {
            border-bottom: none;
        }


        &.MyDownloadable {
            width: auto;
            max-width: initial;
        }
    }

    &-Pagination {
        @include mobile {
            display: flex;
            margin-block-start: 0;
        }
    }

    &-SearchOrderID {
        background: var(--color-white);
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        padding: 24px;

        @include mobile {
            padding: 16px;
        }

        .MyAccountHeader {
            .OrderHeader {
                margin-block-start: 0px;
                font-size: 16px;
                color: var(--color-black);
                text-transform: inherit;
            }
        }

        .SearchOrder {
            display: flex;
            gap: 2%;

            @include mobile {
                flex-direction: column;
                display: flex;
                row-gap: 1rem;
            }

            @include tablet {
                display: flex;
            }

            .MyOrderSearch {
                #mySearch {
                    width: 500px;
                    height: 40px;
                    background: #FAFAFA 0% 0% no-repeat padding-box;
                    box-shadow: inset 1px 1px 6px #00000014;
                    border-radius: 4px;
                    opacity: 1;
                    font-size: 14px;

                    @include mobile {
                        width: 100%;
                        height: 36px;
                    }

                    @include tablet {
                        width: 100%;
                    }
                }
            }

            .MySearchButton {
                @include mobile {
                    display: flex;
                    justify-content: flex-end;
                }

                @include tablet {
                    display: flex;
                    justify-content: flex-end;
                }

                .SearchButton {
                    background: var(--color-white);
                    border: 1px solid var(--primary-base-color);
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--primary-base-color);
                    text-transform: capitalize;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 12px 50px;

                    &:hover {
                        cursor: pointer;
                        background-color: #E53D2F;
                        color: var(--color-white);
                    }

                    @include mobile {
                        padding: 10px 24px;
                        background: var(--primary-base-color);
                        border-radius: 2px;
                        color: var(--color-white);
                        font-size: 12px;

                        &:hover {
                            cursor: pointer;
                            background-color: var(--color-white);
                            color: var(--primary-base-color);
                        }
                    }
                }
            }
        }
    }

    &-heading_order {
        background-color: white;

        .order_pagination {
            nav {
                .Pagination-content {
                    .Pagination-contentLink {
                          display: contents;
                          margin: 0;

                        .Pagination {
                            .Pagination-ListItem {
                                a.PaginationLink.PaginationLink_isCurrent {
                                    border: 1px solid red;
                                    box-shadow: 2px 3px 6px #e73b2533;
                                    border-radius: 7px;
                                    color: white;
                                    height: 34px;
                                    background-color: red;
                                }

                                a.PaginationLink {
                                    font-size: 16px;
                                    color: #707070;
                                    display: flex;
                                }   

                                .Previous_page {
                                    font-size: 16px;
                                    padding-inline-end: 6%;
                                    color: #9D9D9D;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .orderd_list_item {
                        display: flex;
                        column-gap: 13%;
                        font-size: 16px;
                        color: #9D9D9D;
                        margin-block-start: 2%;
                        margin-inline-start: 2%;

                        p {
                            color: #9D9D9D;
                        }
                    }
                }
            }

            @include mobile {
                display: none;
            }

            .orderd_list_item {
                inset-block-end: 4rem;
                display: flex;
                grid-gap: 1rem;
                gap: 1rem;
                font-size: 16px;
                color: #9D9D9D;
                inset-inline-start: 2rem;

                p {
                    color: #9D9D9D;  
                }
            }
        }
    }
}
