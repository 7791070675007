/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable indentation */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrderInformation {
    .ColumnTitle_shipping {
        font-size: 1rem;       
    }

    &-Wrapper {
        margin-block-start: 40px;

        @include mobile {
            margin-block: 20px;
        }

        .Methods {
            .Column_shipping {
                .ColumnTitle_shipping {
                    font-size: 16px;
                }
            }
        }
    }

    &-Shipping_Block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
    }

    &-Methods {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .MyAccountOrderInformation-Column_shipping {
            height: 109px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 1px 1px 13px #00000005;
            border-radius: 3px;
            opacity: 1;
            padding: 12px 14px;
            line-height: 30px;

            .MyAccountOrderInformation-ColumnTitle_shipping {
                text-align: start;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0px;
                color: #0F0F0F;
                opacity: 1;

                @include mobile {
                    font-size: 14px;
                }
            }

            .MyAccountOrderInformation-ShippingMethod {
                text-align: start;
                font-size: 14px;
                letter-spacing: 0px;
                color: #0F0F0F;
                opacity: 1;

                @include mobile {
                    font-size: 12px;
                }
            }

            @include mobile {
                width: 100%;
                margin-block-start: 4%;
            }

            .shipping_total {
                display: flex;
                justify-content: space-between;

                .shipping-charges {
                    font-size: 14px;
                    color: #0F0F0F;
                    font-weight: bold;
                }
            } 
        }

        .MyAccountOrderInformation-Column_payment {
            height: 109px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 1px 1px 13px #00000005;
            border-radius: 3px;
            opacity: 1;
            padding: 12px 14px;
            line-height: 30px;

            .MyAccountOrderInformation-ColumnTitle_payment {
                text-align: start;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0px;
                color: #0F0F0F;
                opacity: 1;

                @include mobile {
                    font-size: 14px;
                }
            }

            .MyAccountOrderInformation-PaymentMethod {
                text-align: start;
                font-size: 14px;
                letter-spacing: 0px;
                color: #0F0F0F;
                opacity: 1;

                @include mobile {
                    font-size: 12px;
                }
            }

            @include mobile {
                width: 100%;
            }
        }
    }

    &-Information {
        display: flex;
        gap: 5%;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }
    }

    &-Title {
        font-size: 22px;
        padding-block-end: 10px;
        margin-block-end: 25px;
        border-block-end: var(--my-account-content-border);
    }

    &-AddressWrapper {
        margin-block-start: 20px;
    }

    &-Address {
        .KeyValueTable {
            margin-block-end: 0;

            th,
            td {
                word-break: break-word;

                @include desktop {
                    padding-inline-start: 0;
                }
            }

            tbody {
                tr {
                    &:first-of-type {
                        td,
                        th {
                            padding-block-start: 0;
                        }
                    }
                }
            }
        }
    }

    &-ShippingMethod {
        @include mobile {
            padding-inline-start: 4px;
        }
    }

    &-Column {
        font-size: 14px;
        margin-block-end: 30px;

        &_type {
            &_billingAddress,
            &_shippingAddress {
                @include desktop {
                    margin-inline-end: 25px;
                }
            }
        }

        @include desktop {
            margin-block-end: 0;
        }

        .MyAccountAddressTable {
            .AddressContainer {
                .AddresstypeContainer {
                    .AddressType {
                        display: none;
                    }
                }

                .ShippingAddress {
                    display: none;
                }
            }
        }

        .MyAccountOrderInformation-Address {
            .KeyValueTable {
                display: block;
                line-height: 4px;
                padding-block-start: 2rem;

                tbody {
                    tr {
                        border: none;
                    }
                }
            }
        }

        .OrdersTable {
            .OrderTable1 {
                text-transform: capitalize;
                font-size: 14px;
                padding: 0;
                line-height: 2;

                .Orders {
                    .telephone_no {
                        display: flex;
                        gap: 4%;
                        color: var(--primary-base-color);

                        .telephone_label {
                            color: #0F0F0F;
                        }
                    }
                    
                    .estimate_date {
                        font-weight: bold;

                        .estimate_text {
                            text-transform: initial;
                            font-weight: normal;
                    }
                }
            }

                @include mobile {
                    font-size: 12px;
                }
            }
        }
    }

    &-ColumnTitle {
        font-size: 16px;
        font-weight: 600;
        margin-block-end: 5px;
        line-height: 32px;
        border-bottom: 1px dotted #d5d5d5;

        @include mobile {
            border-radius: 2px;
            font-size: 14px;
        }
    }

    &-PaymentMethod {
        display: flex;
        flex-direction: column;

        span:not(:last-child) {
            margin-block-end: 10px;
        }

        @include mobile {
            padding-inline-start: 3px;
        }
    }

    &-Shipping_Info {
        width: 100%;
        height: 231px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        padding: 24px;
    }

    &-Total_Info {
        width: 50%;
        height: 282px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;

        @include mobile {
            width: 100%;
        }

        .MyAccountOrderTotals-Wrapper {
            margin: auto;
            display: flex;

            .MyAccountOrderTotals-TotalHeader {
                width: 100%;
                padding-inline-start: 2rem;
                padding-inline-end: 2rem;

                .header_summary {
                    font-size: 16px;
                    font-weight: 600;
                    margin-block-end: 5px;
                    line-height: 32px;
                    border-bottom: 1px dotted #d5d5d5;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                .Order_Total {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem;
                    line-height: 3rem;
                    width: 100%;
                }
            }

            @include mobile {
                margin-block-start: 4%;
            }
        }
    }
}
