/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressDashboard {
    font-size: 14px;

    .AddressContainer {
        .AddresstypeContainer {
            .AddressType {
                margin-block-start: 0;

                @include mobile {
                    font-size: 12px;
                }
            }
        }
        
        p {
            margin-block-end: 0;
            line-height: 22px;
        }

        .Address-Line {            
            @include mobile {
                display: flex;
            }
        }

        .Telephoneno {
            margin-block: 24px;

            span {
                color: var(--primary-base-color);
                padding-inline-start: 16px;
            }

            @include mobile {
                margin: 10px 0 16px 0;
            }
        }

        .TaxContent {
            font-size: 16px;

            @include mobile {
                font-size: 12px;

                .Links {
                    font-size: 12px;
                    margin-inline-start: 10px;
                }
            }
        }

        .ShippingAddress {
            button {
                border: none;
                padding: 0;
                height: 20px;
                font-weight: 400;
                text-transform: inherit;
                font-size: 16px;

                span {
                    border-bottom: 1px solid var(--primary-base-color);
                    color: var(--primary-base-color);
                }

                @include mobile {
                    font-size: 12px;
                    justify-content: flex-start;
                }

                &:hover {
                    color: var(--primary-base-color);
                    padding: 0;
                    height: 20px;
                    font-weight: 400;
                }
            }
        }
        
        div {
            .Links {
                border-bottom: 1px solid var(--primary-base-color);
                font-size: 16px;
                font-weight: 400;

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        @include mobile {
            p {
                font-size: 12px;
            }

            .AddresstypeContainer {
                .AddressType {
                    margin-block-end: 12px;
                }
            }
        }
    }
}
