/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable number-max-precision */

.MyAccountAddressPopup {
    @include mobile {
        font-size: 12px;
        inset-block-start: 0px;
        z-index: 801;
        padding-block-start: 10px;
        height: 100%;
    }

    .Popup-Content {
        max-width: 920px;
        width: 100%;
        min-height: 654px;

        @include mobile {
            overflow-x: hidden;
            height: 100%;
            inset-block-start: 5px;
            padding: 0 20px;
        }

        .Popup-Header {
            @include mobile {
                display: block;
            }

            .Popup-Heading {
                border-bottom: 1px solid #E53D2F;
                font-size: 20px;
                font-weight: 600;
                margin: 0;
                padding: 20px 0;

                @include mobile {
                    font-size: 14px;
                }
            }
        }
    }

    .FieldGroup-Wrapper_address {
        .FieldGroup {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 16px;

            @include mobile {
                grid-template-columns: 1fr;
            }
        }
    }

    .Field-Wrapper_type_checkbox {
        label {
            @include mobile {
                width: 100%;
            }
        }

        &:last-child {
            border-bottom: 1px solid #0F0F0F;
            padding-block-end: 20px;
            margin-block-end: 20px;
        }
    }

    .FieldGroup-Wrapper_CompanyName {
        .FieldGroup {
            display: grid;
            grid-template-columns: 1fr;
            gap: 16px;
        }
    }

    .FieldGroup-Wrapper_Region {
        .FieldGroup {
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 16px;

            @include mobile {
                grid-template-columns: repeat(2,1fr);
                gap: unset;
                column-gap: 16px;
            }
        }
    }

    .FieldGroup-Wrapper_street {
        .Field-Wrapper {
            &:not(:first-child) {
                display: none;
            }
        }
    }

    label.Field-CheckboxLabel {
        @include desktop {
            font-size: 14px;
        }
    }

    .FieldSelect-Select {
        background: #FAFAFA;
        box-shadow: inset 1px 1px 6px #00000014;

        @include mobile {
            height: 36px;
            padding: 9px;
            font-size: 12px;
        }
    }

    .Field-Wrapper {
        .Field {
            margin-block-start: 20px;

            @include mobile {
                margin-block-start: 16px;
            }

            .Field-LabelContainer {
                .Field-Label {
                    font-size: 14px;

                    @include mobile {
                        font-size: 12px;
                    }
                }
            }

            input {
                background: #FAFAFA;
                box-shadow: inset 1px 1px 6px #00000014;

                @include mobile {
                    height: 36px;
                }

                &[type='checkbox'] {
                    outline-style: solid #0F0F0F;
                    box-shadow: inset 1px 1px 6px #00000014;

                    + .input-control {
                        max-width: 24px;
                        width: 100%;
                        height: 24px;
                        background: #FAFAFA;
                        box-shadow: inset 1px 1px 6px #00000014;
                    }

                    &:hover {
                        &:not(:disabled) { 
                            + .input-control {
                                background: #E53D2F;
                                max-width: 24px;
                                width: 100%;
                                height: 24px;

                                &::after {
                                    --checkmark-color: #FAFAFA;
                                }
                            }
                        }
                    }

                    &:checked {
                        &:not(:disabled) { 
                            + .input-control {
                                background: #E53D2F;

                                &::after {
                                    --checkmark-color: #FAFAFA;
                                }
                            }
                        }
                    }
                }
            }

            .react-tel-input .selected-flag:hover {
                background-color: unset;
            }
            
            .react-tel-input .flag-dropdown.open .selected-flag {
                background: unset;
            }
            
            .react-tel-input .form-control {
                width: 100%;
                height: 48px;
                background-color: #f5f5f5;
                box-shadow: inset 1px 1px 6px #00000014;
                border: none;
                padding-inline-start: 13%;

                @include mobile {
                    height: 36px;
                    padding-inline-start: 18%;
                    font-size: 12px;
                }
            }
            
            .react-tel-input .invalid-number-message {
                inset-block-start: unset;
                inset-block-end: -20px;
                inset-inline-start: 0;
            }
            
            .react-tel-input .flag-dropdown {
                width: 50px;
            }
        }
    }

    .Save-Address {
        display: flex;
        justify-content: flex-end;

        button {
            text-transform: inherit;
            
            &:hover {
                background-color: #E53D2F;
                color: #fff;
            }
        }
    }
}
