/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable indentation */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderItemsTableRow {
    &-Qty {
        display: table-cell;
        vertical-align: top;

        span {
            margin-block-end: 5px;
        }
    }

    &-QtyList {
        display: flex;
        gap: 4%;
        color: #707070;

        li {
            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    &-QtyList_Quantity {
        color: #707070;

        .shipped_quantityData {
            display: flex;
            gap: 1rem;
        }

        li {
            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    &-WrapWrapper {
        font-size: 14px;
        word-break: break-word;

        td {
            padding-inline: 10px;
            letter-spacing: 0px;
            color: #000000;
            text-transform: capitalize;
            opacity: 1;
            font-size: 14px;
            font-weight: 600;

            @include desktop {
                &:nth-child(n+3) {
                    text-align: center;
                }
            }

            @include mobile {
                &::before {
                    padding-inline-end: 10px;
                    display: inline-block;
                    color: $black;
                    font-weight: 700;
                    vertical-align: inherit;
                    font-size: 12px;
                }
            }

            @include mobile {
                width: unset;
            }
        }

        tr {
            &:first-child {
                td:first-child {
                    padding-block-start: 15px;
                }
            }

            &:last-child {
                td:last-child {
                    padding-block-end: 15px;
                }
            }
        }

        @media print {
            break-inside: avoid;
        }

        .MyAccountOrderItemsTableRow-Inclusive_text {
            .Inclusive_Tax_text {
                text-align: end;
                color: #707070;
                font-size: 14px;

                @include mobile {
                    font-size: 9px;
                }
            }

            @include mobile {

                p {
                    margin-block-end: 0;
                }
            }
        }

        .AccountOrderImg {
            display: grid;
            grid-template-columns: 1fr 5fr 1fr;
            padding: 10px;

            .product_sku {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;

                @include mobile {
                    min-width: 10px;
                    line-height: 26px;
                }
                
                @include desktop {
                    a {
                        width: 60%;
                    }
                }

                td {
                    font-size: 14px;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                .data_sku {
                    @include mobile {
                    white-space: nowrap;
                    width: 50px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    }
                }
            }

            .main-td {
                 .MyAccountOrderItemsTableRow-Small_img {
                    .small_img_img {
                        width: 110px;
                        height: 110px;

                        @include mobile {
                            width: 114px;
                            height: 114px;
                        }
                    }
                }
            }

            @include mobile {
                padding: 10px;
            }
        }
    }

    &-RowWrapper {
        font-size: 14px;
        word-break: break-word;

        td {
            padding-inline: 10px;
            letter-spacing: 0px;
            color: #000000;
            text-transform: capitalize;
            opacity: 1;
            width: 49%;
            font-size: 14px;
            font-weight: 600;

            @include desktop {
                &:nth-child(n+3) {
                    text-align: end;
                }
            }

            @include mobile {
                &::before {
                    padding-inline-end: 10px;
                    display: inline-block;
                    color: $black;
                    font-weight: 700;
                    vertical-align: inherit;
                    font-size: 12px;
                }
            }

            .Qty_subtotal {
                padding-block-start: 32px;
                padding-inline-start: 10px;
                padding-inline-end: 10px;
                color: #000000;
                text-transform: capitalize;
                opacity: 1;
                font-size: 14px;
                font-weight: 600;
        }

            .qty_subt {
                padding-inline-start: 10px;
                padding-inline-end: 10px;
                color: #000000;
                text-transform: capitalize;
                opacity: 1;
                font-size: 16px;
                font-weight: 600;
                text-align: end;
                line-height: 3rem;

                @include mobile {
                    display: flex;
                    margin: 0;
                    flex-flow: nowrap;
                    width: 100%;
                    justify-content: flex-start;
                    column-gap: 10px;
                    align-items: center;
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }

                @include mobile {
                    font-size: 12px;
                }
            }

            @include mobile {
                width: unset;
            }
        }

        tr {
            &:first-child {
                td:first-child {
                    padding-block-start: 15px;
                }
            }

            &:last-child {
                td:last-child {
                    padding-block-end: 15px;
                }
            }
        }

        @media print {
            break-inside: avoid;
        }

        ul.MyAccountOrderItemsTableRow-SeedQtyList {
            display: flex;
            gap: 2%;
            justify-content: flex-end;

            li::before, ul > li::before {
                content: ' ';
            }

            @include mobile {
                justify-content: flex-start;
                padding-inline-start: 48%;
                padding-block-start: 16%;
            }

            li::before {
                @include mobile {
                    font-size: 9px;
                }
            }
        }

        .MyAccountOrderItemsTableRow-Qty_subtotal {
            padding: 10px 10px 10px 0px;

            .Option_label_id {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                padding-inline-end: 1rem;
                gap: 5px;

                strong {
                    display: flex;
                    gap: 2px;
                    font-weight: normal;
                }

                @include mobile {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 12px;
                    padding-block-start: 6px;
                    gap: 1rem;
                }
            }

            td {
                width: 24%;
            }

            @include mobile {
                padding: 0;
            }
        }

        .MyAccountOrderItemsTableRow-Inclusive_text {
            .Inclusive_Tax_text {
                text-align: end;
                color: #707070;
                font-size: 14px;

                @include mobile {
                    font-size: 9px;
                }
            }

            @include mobile {

                p {
                    margin-block-end: 0;
                }
            }
        }

        .AccountOrderImg {
            display: flex;
            padding: 10px;

            .product_sku {
                display: flex;
                min-width: 69rem;
                flex-direction: column;

                @include mobile {
                    min-width: 10px;
                    line-height: 20px;
                }

                td {
                    font-size: 14px;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                .data_sku {
                    @include mobile {
                    white-space: nowrap;
                    width: 50px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    }
                }
            }

            .main-td {
                 .MyAccountOrderItemsTableRow-Small_img {
                    .small_img_img {
                        width: 110px;
                        height: 110px;

                        @include mobile {
                            width: 114px;
                            height: 114px;
                        }
                    }
                }
            }

            @include mobile {
                padding: 10px;
            }
        }
    }

    &-Row {
        @include mobile {
            td {
                padding: 5px 10px;

                strong {
                    vertical-align: top;
                    margin-inline-end: 15px;
                }
            }

            &_isOptions {
                td {
                    &::before {
                        content: none;
                    }

                    &:first-child {
                        &::after {
                            content: ":";
                            display: inline-block;
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }

    &-Name {
        letter-spacing: 0px;
        color: var(--primary-base-color);
        opacity: 1;

        strong {
            font-size: 16px;
        }

        @include desktop {
            font-size: 16px;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &-EnteredRow {
        td {
            padding-block-start: 0;
            padding-block-end: 5px;
            padding-inline: 10px;

            @include desktop {
                &:first-of-type {
                    padding-inline-start: 20px;
                }

                &:nth-child(n+3) {
                    text-align: end;
                }
            }

            @include mobile {
                &[data-th] {
                    &::before {
                        content: attr(data-th) ": ";
                        display: inline-block;
                        color: $black;
                        font-weight: 700;
                        vertical-align: inherit;
                        padding-inline-end: 10px;
                    }
                }
            }
        }

        &_isLastOptionItem {
            @include desktop {
                td {
                    padding-block-end: 15px;
                }
            }
        }
    }

    &-EnteredLabel {
        td {
            padding: 10px;

            @include desktop {
                padding: 5px 20px;
            }
        }
    }

    &-OptionLabel {
        &:first-child {
            margin-block-start: 0;
        }

        margin-block-start: 15px;
        margin-block-end: 5px;
    }

    &-OptionValue {
        margin-block-end: 5px;

        &>* {
            display: block;
            position: relative;
            word-break: break-all;
        }
    }
}
