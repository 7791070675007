/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountInformation {
    &-Wrapper {
        .Form {
            .FieldForm {
                &-Fields {
                    justify-content: space-between;
                    width: 100%;
                    margin-block-end: 0;

                    @include mobile {
                        flex-direction: column;
                    }
                }

                &-Section {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    background: #fff;
                    border-bottom: 1px solid #cbcbcb;
                    padding: 24px;
                    gap: 24px;

                    @include mobile {
                        width: 100%;
                        grid-template-columns: 1fr;
                    }

                    .Field-Wrapper {
                        &:last-of-type {
                            padding-block-end: 40px;
                        }
                    }

                    .Field {
                        &-Wrapper_type_text {
                            grid-column: span 2;

                            @include mobile {
                                grid-column: span 1;
                            }
                        }
                    }

                    &WithSpace {
                        @include mobile {
                            margin-block-start: 16px;
                        }

                        .ShowHide {
                            position: absolute;
                            inset-inline-end: 12px;
                            inset-block-start: 42px;
                            width: 20px;
                            cursor: pointer;

                            svg {
                                fill: #707070;
                            }
                        }

                        .Field-Wrapper_type_text,
                        .Field-Wrapper_type_password {
                            grid-column: span 2;

                            .Field-ErrorMessages {
                                padding: 10px 16px;
                                background: #fff5f4;
                                width: 100%;
                                margin-block-start: 6px;

                                .Field-ErrorMessage {
                                    font-size: 14px;
                                }
                            }
                        }

                        .Field-Wrapper_type_email {
                            grid-column: span 2;
                        }
                    }
                }

                &-Legend {
                    font-size: 20px;
                    border-block-end: 1px solid #e53d2f;
                    margin-block-end: 16px;
                }
            }

            .Field {
                margin: 0;

                &_type {
                    &_checkbox {
                        width: max-content;
                    }
                }

                .Field-LabelContainer {
                    label {
                        font-size: 14px;
                        padding-block-end: 10px;
                    }
                }

                input {
                    width: 100%;
                    background: #fafafa;
                    box-shadow: inset 1px 1px 6px #00000014;
                    padding: 10px 30px 10px 20px;

                    &[type="checkbox"] {
                        &:hover {
                            &:not(:disabled) {
                                + .input-control {
                                    background: #e53d2f;

                                    &::after {
                                        --checkmark-color: #fafafa;
                                    }
                                }
                            }
                        }

                        &:checked {
                            &:not(:disabled) {
                                + .input-control {
                                    background: #e53d2f;

                                    &::after {
                                        --checkmark-color: #fafafa;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .CustomerFormAction {
                display: flex;
                justify-content: space-between;
                background: #fff;
                padding: 24px;

                .GoBackLink {
                    display: flex;
                    align-items: center;

                    .GoBack {
                        color: #bb8376;
                        border-bottom: 1px solid #bb8376;

                        @include mobile {
                            padding: 16px 45px;
                            border: 1px solid #bb8376;
                            font-size: 12px;
                        }
                    }
                }

                .SaveButton {
                    button {
                        padding: 12px 52px;
                        font-size: 14px;
                        color: var(--primary-base-color);
                        border: 2px solid var(--primary-base-color);
                        text-transform: capitalize;

                        @include mobile {
                            font-size: 12px;
                            padding: 11px 10px;
                            background: var(--primary-base-color);
                            color: #fff;
                        }

                        &:hover {
                            background: var(--primary-base-color);
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
