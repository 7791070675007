/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderTotals {
    &-Wrapper {
        tr {
            &:not(.MyAccountOrderTotals-GrandTotal) {
                th {
                    font-weight: normal;
                    word-break: break-word;
                }
            }

            th,
            td {
                @include desktop {
                    text-align: end;
                }
            }

            td {
                text-align: end;
                word-wrap: break-word;
            }

            @include mobile {
                display: flex;
                justify-content: space-between;
            }

            @media print {
                th {
                    text-align: end;
                }
            }
        }

        @include mobile {
            display: block;
            margin-block-start: 15px;
        }

        @media print {
            border-block-start: var(--table-body-border);
            break-inside: avoid;
        }
    }

    &-GrandTotal {
        font-weight: bold;
    }

    &-TotalHeader {
        width: 48rem;

        @include mobile {
            width: unset;
        }

        .Order_Total {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            line-height: 3rem;
            width: 100%;

            tr {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
            }

            .Grand_total {
                border-bottom: 1px dotted #d5d5d5;
                border-top: 1px dotted #d5d5d5;
                line-height: 53px;
            }

            .taxes {
                font-size: 12px
            }
        }
    }
}
