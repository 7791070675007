/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOverlay {
    &-PersonalInfoLegend {
        padding-block: 8px;
    }

    &-SignUpLegend {
        padding-block: 28px 8px;
    }

    &-SignUpButton {
        margin-block-start: 24px;

        @include mobile {
            margin-block-end: 18px;
        }
    }

    &-SignInButton {
        height: fit-content;
    }

    &-PasswordBlock {
        @include wide-desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 16px;
        }
    }
}

.Create_account {
    &-nonActiveTab {
        display: none;
    }

    &-Activetab {
        display: block;
    }

    &-TabHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-Information {
        display: flex;
        flex-direction: column;
    }
    
    &-red {
        color: red;
        margin: 10px 0 0;
    }

    &-name {
        display: flex;
        column-gap: 50px;

        @include mobile() {
            flex-direction: column;
        }

        @include tablet {
            flex-direction: column;
        }

        .Field-Wrapper {
            .Field_type_text {
                &:has(#hearAboutus) {
                    margin-block-start: 24px;
                }

                margin-block-start: 10px;
            }

            .Field_type_select {
                &:has(#monthly_turnover_wrapper) {
                    @include desktop {
                        .FieldSelect {
                            margin-block-start: 16px;
                        }
                    }
                }
            }
        }
    }

    &-Select {
        display: flex;
        column-gap: 10px;
        width: 100%;

        @include mobile() {
            flex-direction: column;
        }

        .Field-Wrapper {
            .Field_type_email {
                .react-tel-input {
                    .form-control {
                        padding-inline-start: 11%;

                        @include mobile {
                            padding-inline-start: 20%;
                        }
                    }
                } 
            }
        }
    }
    
    &-Field {
        width: 100%;
    }

    &-selectdiv {
        width: 100%;

        @include mobile {
            &:has(.react-tel-input .invalid-number-message) {
                padding-block-end: 24px;
            }
        }

        .react-tel-input {
            .selected-flag:hover {
                background-color: unset;
            }
        
            .flag-dropdown.open .selected-flag {
                background: unset;
            }
        
            .form-control {
                width: 100%;
                height: 48px;
                background-color: #f5f5f5;
                box-shadow: inset 1px 1px 6px #00000014;
                border: none;
                padding-inline-start: 20%;
            }
        
            .invalid-number-message {
                inset-block-start: unset;
                inset-block-end: -20px;
                inset-inline-start: 0;
                font-size: 12px;
                color: var(--primary-error-color);
            }
        
            .flag-dropdown {
                width: 50px;
            }
        }

        label {
            font-size: 16px;
            padding-block-end: 8px;
        }

        select {
            @include desktop() {
                width: 200px;
                background-color: whitesmoke;
                box-shadow: inset 1px 1px 6px #00000014;
                border: none;
                font-size: 23px;
            }
        }
    }

    &-CheckBoxes {
        margin-block-end: 20px;

        .ErrorCheckBox {
            display: none;
        }

        .ErrorCheckBox_isActive {
            display: block;

            p {
                color: var(--primary-error-color);
                margin-block-start: 10px;
                margin-block-end: 0px;
            }
        }
    }

    &-radiobutton {
        display: flex;

        @include desktop {
            column-gap: 10px;
            width: 30%;
        }
    }

    &-CheckoutLabel {
        font-size: 15px;

        .requiredTagSymbol {
            color: var(--required-text-color);
        }
    }

    &-Select_files {
        display: flex;
        column-gap: 50px;
        width: 100%;
        padding-block-start: 12px;

        @include mobile() {
            margin-block-start: 18px;
            flex-direction: column;
            gap: 4rem;
        }

        .Field-Wrapper {
            &_type_file {
                .Field-LabelContainer {
                    label {
                        &.Field-Label {
                            font-weight: 500;
                            pointer-events: none;
                            margin-block-start: -50px;

                            @include mobile {
                                margin-block-start: -37px;   
                            }
                        }
                    }
                }

                .FieldFile-AllowedTypes {
                    display: none;
                }

                .Field-ErrorMessages {
                    padding-block-start: 20px;
                }

                input {
                    width: 100%;
                    background-color: #f5f5f5;
                    border-style: none;
                    border-radius: 3px;
                    height: 0px;
                    pointer-events: none;

                    + label {
                        align-items: center;
                        border: 1px solid var(--input-border-color);
                        background-color: #f5f5f5;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        height: 45px;
                        width: 100%;
                        box-shadow: inset 1px 1px 6px #00000014;

                        &.success {
                            border: 1px solid var(--primary-success-color);
                        }

                        span {
                            color: #fff;
                            background: #BB8376;
                            text-align: center;
                            font-size: 14px;
                            width: auto;
                            font-weight: 500;
                            padding: 9px 6px;
                            margin-inline-end: 16px;
                            border-radius: 4px;
                            text-transform: capitalize;
                        }

                        p {
                            color: #9d9d9d;
                            margin-inline-start: 16px;
                            font-size: 14px;
                        }

                        .successTick {
                            margin-inline-end: 12px;
                        }
                    }
                }
            }
        }
    }

    &-cpatcha {
        width: 259px;
        height: 59px;
    }

    &-submit_button {
        @include desktop {
            width: 20%;
            padding: 12px;
            margin-block-start: 20px;
        }
    }

    &-card {
        margin-block-end: 30px;
        background-color: white;
        padding: 2% 2%;

        @include mobile {
            padding: 20px;
        }

        h3 {
            margin-block-end: 10px;
            margin-block-start: 0;
        }
    }
}

.contractSection {
    .ContractContent {
        margin-block-end: 35px;
    }

    .Create_account-Select_files {
        @include desktop {
            width: 50%;
        }

        margin-block-end: 20px;

        .Field {
            margin-block-start: 0;
        }
    }
}

.Field {
    &-Wrapper {
        width: 100%;

        .Field_type_select {
            .FieldSelect {
                &-Select {
                    background-color: #f5f5f5;
                    border: 1px solid #f5f5f5;
                    box-shadow: inset 1px 1px 6px #00000014;
                }

                &-Clickable {
                    svg {
                        fill: var(--primary-base-color);
                    }
                }
            }
        }
    }

    &-Wrapper_type_checkbox {
        font-size: 14px;
    }
}

.fileUploadEg {
    margin-block-start: 20px;
}

.boldfont {
    margin-block-start: 24px;
    font-weight: bold;

    a {
        color: var(--primary-base-color);
    }
}

.font_footer {
    margin-block-start: 24px;
}

optgroup {
    font-size: 23px;
}
