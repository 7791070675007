/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable indentation */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderItemsTable {
    &-OrderActions {
        margin-block-end: 10px;
        padding: 2rem;

        .OrderNumberAction_Button {
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            @include mobile {
                flex-direction: column;
            }

            .Tracking_Button {
                text-decoration: underline;
                text-underline-position: under;
                font-size: 14px;

                a {
                    color: red;
                }
            }

            .OrderNumberAction {
                .Tracking {
                    @include mobile {
                        display: flex;
                        justify-content: space-between;
                        margin-block-start: 1rem;
                    }
                }
            }
        }
    }

    &-OrderTitle {
        font-size: 22px;

        .Tracking {
            display: flex;
            gap: 1rem;
            margin-block-start: 1rem;
            width: 400px;
            align-items: center;

            .Tracking_text {
                font-size: 16px;
                font-weight: bold;
                width: 35%;
            }

            .Tracking_data {
                font-size: 16px;
                width: 55%;
                padding-inline-start: 10px;
            }
        }

        @include mobile {
            font-size: 18px;
        }
    }

    &-PrintOrder {
        font-weight: normal;
        margin-inline-start: 10px;
    }

    &-Headings {
        @include mobile {
            display: none;
        }
    }

    &-ProductsWrapper {
        background-color: #ffffff;
        border: var(--table-body-border);

        &:not(:first-of-type) {
            margin-block-start: 0;
        }

        .row_tracking-order_tracking {
            border-bottom: 1px solid #f2f2f2;
            border-top: 1px solid #f2f2f2;

            .status_tracking {
                margin-block-end: 20px;
                padding: 35px;
                margin-block-start: 20px;

                .justify-content-between {
                    display: flex;

                    .order {
                        width: 50%;

                        @include mobile {
                            &::before {
                                height: 63px;
                                width: 3px;
                            }
                        }
                    }

                    .shipped {
                        @include mobile {
                            inset-block-start: 21px;
                            height: 93px;
                        }

                        @include mobile {
                            &::before {
                                height: 73px;
                                width: 3px;
                            }
                        }
                    }

                    .delivery {
                        &::before {
                            display: none;
                        }
                    }

                    .shipped,
                    .delivery {
                        width: 25%;

                        @include mobile {
                            inset-block-start: 2rem;
                            width: unset;
                        }
                    }

                    .order-tracking,
                    .order-completed {
                        position: relative;
                        display: block;
                        border-color: red;
                        border-width: 0px;

                        @include mobile {
                            &:not(:last-child)::before {
                                content: "";
                            }
                        }

                        &::before {
                            content: "";
                            display: block;
                            background-color: red;
                            inset-block-start: 13px;
                            position: absolute;
                            z-index: 0;

                            @include mobile {
                                inset-inline-start: 7px;
                            }

                            @include desktop {
                                width: 100%;
                                height: 3px;
                            }
                        }

                        .is-pending,
                        .is-complete {
                            display: block;
                            position: relative;
                            border-radius: 50%;
                            padding: 1px;
                            height: 17px;
                            width: 17px;
                            margin-block: 5px;
                            z-index: 2;
                            border-color: red;
                            border-width: 0px;
                            text-align: start;

                            @include mobile {
                                margin-block-end: 4px;
                            }
                        }

                        .is-pending {
                            background-color: #f7f7f7;
                        }

                        .is-complete {
                            background-color: red;
                        }

                        .center {
                            @include mobile {
                                margin: 0;
                                inset-block-start: 4rem;
                            }
                        }

                        .right-c {
                            margin-inline-start: auto;

                            @include mobile {
                                margin-inline-start: 0;
                                inset-block-start: -2rem;
                            }
                        }

                        .completed {
                            border-color: red;
                            border-width: 0px;
                            background-color: red;
                        }

                        .delivered_order {
                            font-size: 14px;
                            color: #000000;
                            position: absolute;
                            inset-block-end: 3rem;
                            inset-inline-start: 10rem;
                        }

                        .delivered_date {
                            font-size: 14px;
                            color: #959595;
                            line-height: 30px;

                            @include mobile {
                                inset-inline-start: 2rem;
                                inset-block-end: 3rem;
                            }
                        }

                        .ordered {
                            inset-inline-end: 0;
                            inset-block-end: 5rem;

                            @include mobile {
                                inset-inline-start: 3rem;
                                inset-block-end: 2rem;
                            }
                        }

                        .order_date {
                            @include mobile {
                                inset-inline-start: 3rem;
                            }
                        }

                        .Shipped {
                            inset-inline-start: -2rem;
                            inset-block-end: 5rem;

                            @include mobile {
                                inset-inline-start: 3rem;
                                inset-block-start: -21px;
                            }
                        }

                        .Delivered {
                            inset-block-end: 5rem;
                            display: flex;
                            width: 100%;
                            justify-content: flex-end;

                            @include mobile {
                                display: block;
                                inset-inline-start: 3rem;
                                inset-block-start: -48px;
                            }
                        }

                        .shipped_date {
                            inset-inline-start: -3rem;

                            @include mobile {
                                inset-inline-start: 3rem;
                                inset-block-start: -3rem;
                            }
                        }

                        .delivery_date {
                            display: flex;
                            width: 100%;
                            justify-content: flex-end;

                            @include mobile {
                                inset-block-start: -6rem;
                                inset-inline-start: 3rem;
                                display: block;
                            }
                        }
                    }

                    .order-tracking {
                        border-color: #f7f7f7;

                        &::before {
                            background-color: #f7f7f7;
                        }
                    }

                    @include mobile {
                        display: block;
                    }
                }

                @include mobile {
                    margin-block-end: -42px;
                    padding: 15px;
                    margin-block-start: 0;
                }
            }

            @include mobile {
                display: block;
            }
        }
    }

    &-Products {
        width: 100%;
        font-size: 16px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        max-width: 100%;

        thead {
            tr {
                th,
                td {
                    &:nth-child(n + 3) {
                        text-align: end;
                    }
                }
            }

            @include mobile {
                display: block;
            }
        }

        tbody {
            border-block-start: var(--table-body-border);

            &:first-of-type {
                border-block-start: none;
            }

            tr {
                &:not(:last-child) {
                    border-block-end: none;
                    display: flex;
                    flex-direction: column;
                }
            }

            .MyAccountOrderItemsTableRow {
                &-Row {
                    border-block-start: none;
                }

                &-EnteredLabel,
                &-EnteredRow {
                    border-block: unset;
                }

                @include mobile {
                    &-Name {
                        border-block: unset;
                    }
                }
            }

            @include mobile {
                display: block;

                tr {
                    display: block;

                    td {
                        display: block;
                        padding: 0;
                    }
                }
            }
        }

        @include mobile {
            display: block;
        }
    }

    @include mobile {
        table {
            tbody {
                tr,
                td {
                    border-bottom: none;
                }
            }
        }
    }

    &-Quantity,
    &-Subtotal,
    &-Price {
        width: 15%;
    }

    &-Comments {
        &Title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.1;
            margin-block-end: 10px;
            margin-block-start: 20px;
        }

        &List {
            display: flex;
            flex-direction: column;
        }
    }

    &-Comment {
        border-inline-start: var(--my-account-content-border);
        margin-block-end: 30px;

        dt {
            font-weight: bold;
            margin-block-end: 5px;
            margin-inline-start: 10px;
        }

        dd {
            margin-inline-start: 10px;
            word-break: break-word;
        }
    }
}
