/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CreateAccount {
    background: #f5f5f5;
    
    .ContentWrapper {
        @include desktop() {
            padding-block-start: 25px;
            padding-block-end: 30px;
        }

        @supports (-webkit-touch-callout: none) {
            padding-block-end: 80px;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            margin: 0 auto;
            grid-template-columns: none;
            gap: 30px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-block-start: 20px;
            color: #fff;
            background-color: var(--primary-base-color);
            border-radius: 5px;
            height: 50px;
            cursor: pointer;

            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 55%;
                min-width: 0px;
                margin: auto;
                font-size: 16px;
            }
        }

        > div {
            flex: 1;
            margin: 20px 10px;
        }

        input {
            background-color: #f5f5f5;
            box-shadow: inset 1px 1px 6px #00000014;
            border: none;

            @include mobile {
                width: 100%;
                height: 40px;
            }

            @include desktop {
                width: 100%;
                margin: auto;
            }
        }

        .Field {
            [type='checkbox'] {
                + .input-control {
                    max-width: 30px;
                    width: 24px;
                    height: 24px;
                    border-radius: 3px;
                }
            }

            &-Label {
                font-size: 16px;
            }
        }

        p {
            margin-block-end: 22px;
        }
    }

    .Loader {
        margin: 0;
    }

    &-signUpContent {
        background: #fff;
        padding: 30px;

        .heading {
            margin-block: 10px 30px;
            font-size: 32px;

            @include mobile {
                font-size: 24px;
                line-height: 34px;
            }
        }

        h2 {
            text-transform: none;
        }

        p {
            color: black;
            font-size: 16px;
        }

        @include mobile {
            margin-block-end: 30px;

            h2 {
                margin-block: 8px;
                font-size: 16px;
            }

            p {
                text-align: justify;
                line-height: 20px;
                font-size: 14px;
            }
        }

        .CreateAccount_list {
            .promotion_list {
                padding-inline-start: 5rem;
                color: #707070;

                @include mobile {
                    padding-inline-start: 1rem;
                }

                li {
                    list-style-type: circle;
                    color: #707070;
                    font-size: 14px;

                    @include mobile {
                        font-size: 12px;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .CreateAccount_laws {
            .laws_list {
                padding-inline-start: 5rem;
                color: #707070;

                @include mobile {
                    padding-inline-start: 1rem;
                }

                .law_list {
                    li {
                        list-style-type: revert;
                        color: #707070;
                        font-size: 14px;

                        @include mobile {
                            font-size: 12px;
                        }

                        &::before {
                            display: none;
                        }
                    }

                    .laws_sublist {
                        padding-inline-start: 2rem;
                    }
                }
            }
        }
    }

    .Footer {
        @include mobile {
            background-color: #FCFCFC;
        }

        &-CopyrightContentWrapper {
            @include mobile {
                background-color: #FCFCFC;
            }
        }
    }
}
