/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountTabListItem {
    --border-width: 1px;
    --text-shadow: none;

    padding-inline-start: 0;
    margin-block-end: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;

        button {
            justify-content: flex-start;
            gap: 5px;
            color: var(--primary-base-color);

            @include mobile {
                justify-content: space-between;
            }

            @include tablet {
                white-space: nowrap;
            }

            &::after {
                content: none;
            }
        }
    }

    .MyAccountTabListItem-Button {
        height: 6rem;
        letter-spacing: 0;
        color: #707070;
    }

    &-IconName {
        display: flex;
        align-items: center;
        gap: 5px;

        @include tablet {
            white-space: nowrap;
        }
    }

    &_isActive,
    &:hover {
        .MyAccountTabListItem-Button {
            color: var(--primary-base-color);
            background-color: #FFF5F4;

            @include mobile {
                background-color: transparent;
            }

            .MyAccountTabListItem-IconName {
                color: var(--primary-base-color);

                svg {
                    g {
                        path {
                            fill: var(--primary-base-color);
                        }
                    }
                }
            }

            svg {
                fill: var(--primary-base-color);
            }
        }
    }

    &:hover {
        .MyAccountTabListItem-Button {
            font-weight: 400;
        }
    }

    &_isActive {
        .MyAccountTabListItem-Button {
            font-weight: 700;
        }

        @include mobile {
            display: block;
            border-bottom: 1px solid var(--primary-base-color);
        }
    }

    &-Button {
        padding: 10px 0;
        min-width: 100%;
        min-height: 38px;
        text-align: start;
        text-shadow: var(--text-shadow);
        letter-spacing: 03ex;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            padding: 10px 24px;
            width: 260px;
        }

        @include tablet {
            width: unset;
        }

        @include mobile {
            white-space: nowrap;
        }

        .ChevronIcon {
            width: 14px;
            height: 14px;
            display: inline-block;

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }
    }
}
