/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOverlay {
    &-SignInButton {
        margin-block-start: 20px;
        line-height: 20px;

        button {
            width: 100%;
            background-color: var(--primary-base-color);
            text-transform: capitalize;
            font-size: 16px;
        }
    }

    #forgot-password-label {
        margin-block-end: 0;
    }

    .CreateAccount {
        margin-inline-start: 5px;
    }

    &-ForgotPassword {
        color: var(--primary-base-color);
        text-decoration: underline;
        font-size: 16px;
    }

    &-recaptcha {
        @include mobile {
            display: flex;
            justify-content: center;
        }
    }
}
