/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountCompanyCredit {
    .CompanyCredit-container {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 24px;

        @include mobile {
            display: block;
        }

        div {
            padding: 24px;
            background: #fff;

            @include mobile {
                display: flex;
                justify-content: space-between;
                max-width: 100%;
                padding: 18px 16px;
                margin-block: 16px;
            }

            .Balance-Heading {
                font-size: 16px;
                margin-block-start: 0;

                @include mobile {
                    margin-block-end: 0;
                }
            }

            .Balance-amount {
                font-size: 20px;
                font-weight: 600;

                @include mobile {
                    color: var(--primary-base-color);
                }
            }
        }

        .Outstanding-Balance {
            .Balance-amount {
                color: var(--primary-base-color);
            }
        }
    }

    .CreditTableContainer {
        background: #fff;
        padding: 24px;
        margin-block-start: 24px;

        .CreditHeading {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            margin-block-end: 14px;
        }

        .CreditTable {
            .Credit {
                width: 100%;

                .border-bottom {
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    font-size: 16px;
                    line-height: 30px;

                    th {
                        background: none;
                    }
                }

                tr {
                    border-bottom: none;

                    .TextChange {
                        text-transform: capitalize;
                    }
                }

                td {
                    font-size: 14px;
                    padding: 12px;
                    vertical-align: middle;
                    
                    .time {
                        color: #BB8376;
                    }
                }
            }
        }
    }

    .Orders {
        &-Wrapper {
            margin-block-start: 12px;

            @include mobile {
                margin-block-start: 0;
            }
      
            .Inner {
                padding: 12px;
                border-bottom: 1px solid #fafafa;
                box-shadow: #00000005;
                background: #fff;
      
                .OrderDetail,
                .OrderStatus,
                .OrderNumber {
                    display: flex;
                    justify-content: space-between;
                    margin-block-end: 12px;
                    align-items: center;
      
                    span {
                        color: #707070;
                        font-size: 12px;
                    }

                    .bold {
                        font-weight: bold;
                        color: #000;
                        margin-inline-start: 12px;
                    }
                }
      
                .OrderNumber {
                    .seperator {
                        flex: 1 0 auto;
                        margin-inline-start: 10px;
                        height: 1px;
                        background-color: #707070;
                    }
                }

                .OrderStatus {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .status {
                        .wait {
                            font-weight: bold;
                            color: #bb8376;
                            margin-inline-start: 12px;
                        }
                    }

                    .shipTo,.totalOrder {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        .dateTime {
                            display: flex;
                            gap: 2px;
                            color: #000;
                            font-weight: bold;
                            
                            span {
                                color: #000;
                            }

                            .time {
                                color: #BB8376;
                            }
                        }

                        .bold {
                            .BalancePrice {
                                font-weight: bold;
                                color: #000;
                                margin-inline-start: 12px;
                            }
                        }
                    }
                }
      
                .OrderAction {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;
                    align-items: center;

                    button {
                        font-size: 14px;
                        color: var(--primary-base-color);
                        border-bottom: 1px solid var(--primary-base-color);
                        cursor: pointer; 
                        margin-inline-end: 12px;
                    }
      
                    .Vline {
                        height: 12 px;
                        width: 1px;
                        background-color: #707070;
                    }
                }

                .OrderDetail {
                    flex-direction: column;
                    gap: 16px;

                    .OrderDate,.OrderAction {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .Separator {
        width: 80%;
        margin: auto;
        height: 1px;
        background-color: #F2F2F2;
    }
}

