/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
}

.MyAccountOrderTableRow {
    height: 36px;

    &-DownloadLink {
        display: block;
    }

    .view_button {
        font-size: 14px;
        color: var(--primary-base-color);
        text-decoration: underline;
        
        &:hover {
            cursor: pointer;
        }
    }

    .Re_button {
        font-size: 14px;
        color: var(--primary-base-color);
        margin-inline-start: 0%;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    .view_reorder_button {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .Pending {
        color: #BB8376;
    }
    
    .Completed {
        color: #00741b;
    }

    .cancel {
        color: var(--primary-base-color);
    }
}

.Orders {
    &-Wrapper {
        background-color: #fafafa;
        margin-block-start: 12px;

        .seperator:not(:last-child) {
            flex: 1 0 auto;
            margin-block-start: 10px;
            height: 1px;
            background-color: #F2F2F2;
        }

        .Inner {
            padding: 12px;

            @include mobile {
                background-color: white;
                border-bottom: 1px solid #F2F2F2;
            }

            .OrderDetail,
            .OrderStatus,
            .OrderNumber {
                display: flex;
                justify-content: space-between;
                margin-block-end: 12px;
                align-items: center;

                span {
                    color: #707070;
                    font-size: 12px;
                }

                .bold {
                    font-weight: bold;
                    color: #000;
                    margin-inline-start: 12px;
                }
            }

            .OrderNumber {
                .seperator {
                    flex: 1 0 auto;
                    margin-inline-start: 10px;
                    height: 1px;
                    background-color: #707070;
                }
            }

            .OrderStatus {
                .status {
                    width: 50%;
                    text-align: end;
                    
                    .done {
                        font-weight: bold;
                        color: #00741b;
                    }

                    .status_view {
                        inset-inline-end: 1.5rem;
                    }

                    .wait {
                        font-weight: bold;
                        color: #bb8376;
                    }
                }

                .shipTo {
                    width: 50%;
                }
            }

            .OrderAction {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                align-items: center;

                a {
                    font-size: 12px;
                    color: var(--primary-base-color);
                    text-decoration: underline;
                }

                .Vline {
                    height: 12px;
                    width: 1px;
                    background-color: #9D9D9D;
                }
            }
        }
    }
}
