/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountCustomerTable {
    min-width: 40%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    box-shadow: 1px 1px 13px #00000005;
    background: #fff;
    padding: 20px;
    margin-block-end: 24px;

    @include mobile {
        grid-gap: 0;
        margin: 12px 0;
        padding: 16px;
    }

    .DashboardAddress {
        .Address-heading {
            margin-block: 0;
            margin-block-end: 12px;
            font-size: 16px;
        }

        .CustomerName {
            p {
                font-size: 14px;
                margin-block-end: 10px;
            }

            .Email {
                color: #BB8376;
            }
        }
    }

    .Address_Action {
        display: flex;
        column-gap: 10px;
        white-space: nowrap;
        align-items: center;
        
        button {
            font-size: 16px;
            color: var(--primary-base-color);
            border-bottom: 1px solid var(--primary-base-color);
            cursor: pointer;

            @include mobile {
                font-size: 12px;
            }
        }

        .mobile {
            display: none;

            @include mobile {
                display: initial;
            }
        }

        .desktop {
            @include mobile {
                display: none;
            }
        }
    }

    &-Table {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}
