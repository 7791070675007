/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable no-duplicate-selectors */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
}

.MyAccountMyWishlist {
    --myaccount-wishlist-action-bar-offset: 55px;
    
    background: #ffffff;
    padding: 2rem;

    @include mobile {
        padding: 0;
    }

    &product {
        width: 341px;
    }

    &-Products {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: max-content;
        grid-gap: 0px;

        @include tablet {
            grid-template-columns: repeat(1, 1fr);
            inset-block-start: 0;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(1, 1fr);
            inset-block-start: var(--myaccount-wishlist-action-bar-offset);
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        @include wide-desktop {
            grid-template-columns: repeat(1, 1fr);
            inset-block-start: 0;
        }

        @include mobile {
            grid-column-gap: 28px;
            grid-template-columns: 1fr;
            border: 0;
            max-width: 100%;
            margin: 0 auto;
            margin-block-end: var(--myaccount-wihslist-products-margin-bottom);
        }

        @include desktop {
            grid-column: 2;
        }
    }

    &-NoProducts {
        @include tablet {
            position: relative;
            inset-block-start: 60px;
        }
    }

    &-order_pagination {
        .orderd_list_item {
            display: flex;
            align-items: center;
            column-gap: 3px;
            font-style: italic;
            font-size: 16px;
            color: #9D9D9D;
        }
    }

    &-ActionBar {
        display: flex;
        padding: 0;
        padding-block-end: 12px;
        justify-content: flex-end;

        @include desktop {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
        }

        @include wide-desktop {
            inset-block-start: calc(0px - var(--myaccount-wishlist-action-bar-offset));
        }

        @include mobile {
            display: none;
        }

        @include tablet {
            .AddToCart-Icon {
                display: none;
            }

            inset-block-start: -5.5rem;
        }
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 10px;
    }

    &-NoProductsButton {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-Button {
        @include desktop {
            margin-inline-end: 30px;
            margin-inline-start: 30px;
        }

        @include mobile {
            width: 100%;
        }

        .CartIcon {
            fill: var(--color-white);
            margin-inline-end: 12px;
            width: 20px;
            height: 20px;

            &:hover {
                fill: var(--color-white);
            }
        }
    }

    &-ClearRemoveItemsButton {
        text-transform: uppercase;
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ClearWishlistButton {
        font-weight: 500;
        text-decoration: underline;
        text-underline-position: under;
        color: var(--primary-base-color);
        text-transform: inherit;
        font-size: 16px;
        margin-block-start: -20px;


        @include desktop {
            order: 1;
        }

        &.Button {
            width: auto;
        }


        &-hover {
            font-weight: 500;
            text-decoration: underline;
            text-underline-position: under;
            color: var(--primary-base-color);
            text-transform: inherit;
            font-size: 16px;
            margin-block-start: -20px;  
        }

        @include narrow-big-desktop {
            inset-block-end: 5rem;
        }
    }

    &-ShareWishlistButton {
        .ShareIcon {
            margin-inline-end: 12px;
        }

        &:not([disabled]):hover .ShareIcon_isPrimary {
            fill: var(--primary-dark-color);
        }
    }

    &-TableHead {
        display: grid;
        border-bottom: 1px solid #0f0f0f;
        grid-template-columns: 4fr 3fr 3fr 2fr;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: bold;
        padding: 14px 20px 14px 0px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);
        color: #0f0f0f;

        @include mobile {
            display: none;
        }


        @include tablet {
            grid-template-columns: 4fr 2fr 2fr 2fr;
        }

        span {
            @include tablet {
                margin: auto;
            }
        }
    }

    &-product {
        width: 323px;
    }
}
