/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderTabs {
    display: flex;
    transform: translateY(1px);
    z-index: 1;

    &-Wrapper {
        @include mobile {
            padding: 0;
        }

        .ExpandableContent-Heading {
            text-transform: uppercase;
        }

        .ExpandableContent {
            border-block-end: 0;
        }

        & > :first-child {
            border-block-start: 0;
        }
    }

    .MyAccountOrderTab-Item {
        background-color: var(--color-dark-gray);
        border: var(--table-body-border);
        border-right: none;
        opacity: 0.6;

        &:last-child {
            border-right: var(--table-body-border);
        }

        .MyAccountOrderTab-Button {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            margin: 0;
            padding: 16px;
            cursor: pointer;
        }

        &:hover {
            background-color: var(--color-white);
        }
    }

    .MyAccountOrderTab-Item_isActive {
        opacity: 1;
        background-color: var(--color-white);
        border-bottom: none;

        button {
            color: var(--color-black);
        }
    }
}
