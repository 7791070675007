/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountDashboard {
    @include mobile {
        .MyAccountDashboard-CustomerData {
            background-color: #fafafa;
        }
    }

    &-BlockTitle {
        border-bottom: 1px solid var(--primary-base-color);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        padding-block-end: 6px;
        width: 100%;
        font-weight: 600;
        padding: 16px 0;

        @include mobile {
            font-size: 12px;
            background-color: #fafafa;

            .MyAccountDashboard-showLink {
                display: none;
            }
        }
    }

    .CustomerAccountGrid {
        display: flex;
        flex-wrap: wrap;
        margin-block-start: 16px;

        @include mobile {
            flex-direction: column;
            margin-block-start: 0;
            background-color: #fafafa;
        }
    }

    .NewsletterSubscription {
        max-width: 20%;
        background: #fff;
        margin-inline: 24px;
        margin-block-end: 24px;
        padding: 20px;
        box-shadow: 1px 1px 13px #00000005;

        .Heading {
            margin-block: 0 12px;
            -webkit-margin-after: 12px;
            font-size: 16px;
        }

        .subscribe {
            @include mobile {
                font-size: 12px;
                margin-block-end: 12px;
            }
        }

        .subscribe-bold-text {
            font-weight: 600;
        }
        
        .EditSubscription {
            margin-block-start: 35px;
            font-size: 16px;
            color: var(--primary-base-color);
            border-bottom: 1px solid var(--primary-base-color);
            cursor: pointer;

            @include mobile {
                font-size: 12px;
                margin: 0;
            }
        }

        @include mobile {
            margin: 0;
            margin-block-end: 12px;
            padding: 16px;
            max-width: unset;
            
            .Heading {
                margin-block: 0;
                margin-block-end: 12px;
                font-size: 12px;
            }
        }
    }

    .CreditBlock { 
        min-width: 30%;
        background: #fff;
        margin-inline-end: 24px;
        margin-block-end: 24px;
        padding: 20px; 
        box-shadow: 1px 1px 13px #00000005;

        .Heading {
            margin-block: 0 12px;
            -webkit-margin-after: 12px;
            font-size: 16px;
        }


        .Price {
            color: var(--primary-base-color);
            font-size: 20px;
            font-weight: 600;
        }

        @include mobile {
            margin-inline-end: 0;
            margin-block-end: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .Heading {
                margin-block: 0;
                font-size: 12px;
            }

            .Price {
                font-size: 14px;
            }
        }
    }


    &-DefaultAddress {
        flex: 50% 1;
        background: #fff;
        margin-block: 16px;
        padding: 24px;
        box-shadow: 1px 1px 13px #00000005;

        @include mobile {
            font-size: 12px;
            margin: 0;
            margin-block-start: 12px;
            padding: 16px 12px;
        }
    }

    &-AddressesWrapper {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;

        .MyAccountAddressTable {
            .KeyValueTable-Wrapper {
                display: none;
            }
        } 

        @include mobile {
            flex-direction: column-reverse;
            background-color: #fafafa;
            gap: 0;
        }
    }

    .OrdersTable {
        .Heading-Block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--primary-base-color);

            .OrderHeading {
                font-size: 20px;
                font-weight: 600;
                padding: 16px 0;
                margin: 0;
                
    
                @include mobile {
                    margin: 0;
                    background-color: #fafafa;
                    font-size: 12px;
                    padding: 0;
                    padding-block-start: 10px;
                    padding-block-end: 12px;
                }
            }
        }

        .OrderTable1 {
            padding: 24px;
            background: #fff;   
            margin-block-start: 16px;

            @include tablet {
                padding: 5px;
            }
        }

        .Orders {
            width: 100%;

            .border-bottom {
                border-bottom: 1px solid #000;
                font-size: 16px;
                margin-block-end: 12px;

                th {
                    background-color: transparent;

                    @include desktop {
                        white-space: nowrap;
                    }

                    @include tablet {
                        font-size: 13px;
                        white-space: inherit;
                    }
                }
            }

            tr {
                font-size: 14px;
                border: none;
            }

            td {
                margin: 12px;
                vertical-align: middle;
                font-size: 14px;

                @include tablet {
                    font-size: 11px;
                    white-space: nowrap;
                }

                button {
                    font-size: 14px;
                    color: var(--primary-base-color);
                    border-bottom: 1px solid var(--primary-base-color);
                    cursor: pointer; 
                    margin-inline-end: 12px;

                    @include tablet {
                        font-size: 11px;
                    }
                }

                .VeiwButton {
                    margin-inline-end: 6px;

                    @include mobile {
                        margin-inline-end: 0;
                    }
                }

                .ReorderButton {
                    margin-inline-start: 6px;
                }
            }

            .status {
                color: #BB8376;
                margin-inline-start: 12px;
            }
            
            .complete {
                color: #00741b;
                margin-inline-start: 12px;
            }
        
            .Canceled,
            .Closed {
                color: var(--primary-base-color);
                margin-inline-start: 12px;
            }
        }
    }

    .OrderTableMobile {
        .OrderHeading {
            font-size: 12px;
            font-weight: 600;
            border-bottom: 1px solid var(--primary-base-color);
            padding: 10px 0;
            background: #fafafa;
            margin-block-end: 12px;
        }

        .Orders {
            &-Wrapper {
                margin-block-start: 0;
          
                .Inner {
                    padding: 12px;
                    border-bottom: 1px solid #fafafa;
                    box-shadow: #00000005;
                    background: #fff;
          
                    .OrderDetail,
                    .OrderStatus,
                    .OrderNumber {
                        display: flex;
                        justify-content: space-between;
                        margin-block-end: 12px;
                        align-items: center;
          
                        span {
                            color: #707070;
                            font-size: 12px;
                        }

                        .bold {
                            font-weight: bold;
                            color: #000;
                            margin-inline-start: 12px;
                        }
                    }
          
                    .OrderNumber {
                        .seperator {
                            flex: 1 0 auto;
                            margin-inline-start: 10px;
                            height: 1px;
                            background-color: #707070;
                        }
                    }

                    .OrderStatus {
                        .statusMobile {
                            color: #BB8376;
                            margin-inline-start: 12px;
                        }

                        .complete {
                            color: #00741b;
                            margin-inline-start: 12px;
                        }
                    
                        .Canceled {
                            color: var(--primary-base-color);
                            margin-inline-start: 12px;
                        }
                    }
          
                    .OrderAction {
                        display: flex;
                        justify-content: flex-end;
                        gap: 10px;
                        align-items: center;

                        button {
                            font-size: 14px;
                            color: var(--primary-base-color);
                            border-bottom: 1px solid var(--primary-base-color);
                            cursor: pointer; 
                            margin-inline-end: 12px;
                        }
          
                        .Vline {
                            height: 12 px;
                            width: 1px;
                            background-color: #707070;
                        }
                    }
                }
            }
        }

        .Separator {
            width: 80%;
            margin: auto;
            height: 1px;
            background-color: #F2F2F2;
        }
    }

    &-Logout {
        background: #fff;
        margin-block: 20px;
        padding: 10px;

        button {
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: var(--primary-base-color);
            font-weight: 600;
        }
    }
}
