/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressBook {
    display: grid;
    grid-template-columns: 1fr;

    @include mobile {
        grid-gap: 0px;
    }

    .tab-heading {
        border-bottom: 1px solid var(--primary-base-color);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        padding-block-end: 6px;
        width: 100%;
        font-weight: 600;
        padding: 16px 0;

        @include mobile {
            font-size: 14px;
        }
    }

    button + button {
        margin-inline-start: 12px;

        @include mobile {
            margin-block-start: 14px;
            margin-inline-start: 0;
        }
    }

    .Default-Address {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 24px;

        @include mobile {
            display: block;
        }

        .MyAccountAddressDashboard {
            background: #fff;
            margin-block: 16px;
            padding: 24px;
            box-shadow: 1px 1px 13px #00000005;

            @include mobile {
                padding: 16px 12px;
                margin-block: 16px;
            }
        }
    }

    .ActionBlock {
        padding: 24px;
        background: #fff;
        margin: 24px 0;
        display: flex;
        justify-content: space-between;

        @include mobile {
            margin: 0;
            padding: 20px 16px;
            margin-block-end: 16px;
            display: block;
        }

        @include tablet {
            display: grid;
            grid-template-columns: 8fr 6fr;
            grid-gap: 10px;
        }

        .Left-container {
            h3 {
                margin: 0;

                @include mobile {
                    margin: 10px 0;
                    font-size: 12px;
                }

                @include tablet {
                    font-size: 16px;
                }
            }

            .Action-para {
                @include tablet {
                    margin-block-start: 5px;
                }
            }
        }

        .Right-container {
            @include tablet {
                margin: auto;
            }

            Button {
                height: 46px;
                padding-inline: 40px;
                text-transform: initial;


                @include mobile {
                    width: 60%;
                    font-size: 12px;
                    white-space: nowrap;
                    gap: 10px;
                    padding: 0;
                }

                @include tablet {
                    padding-inline: 10px;
                    font-size: 12px;
                }

                svg {
                    fill: var(--primary-base-color);
                    width: 15px;
                    height: 15px;

                    @include tablet {
                        width: 16px;
                        height: 15px;
                        margin-inline-end: 10px;
                    }

                    margin-inline-end: 16px;
                    border: 1px solid var(--primary-base-color);
                    border-radius: 20px;

                    @include mobile {
                        width: 12px;
                        height: 12px;
                        margin-inline-end: 0;
                    }
                }
                
                &:hover {
                    background: var(--primary-base-color);
                    color: #fff;
                    height: 46px;
                    padding-inline: 40px;

                    svg {
                        fill: #fff;
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }

    .NormalAddress {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 24px;

        @include mobile {
            display: block;
        }

        .MyAccountAddressTable {
            padding: 16px 12px;
            background: #fff;

            .KeyValueTable-Wrapper {
                min-height: 230px;
            }
            
            .KeyValueTable {
                thead {
                    display: none;
                }

                tbody {
                    tr {
                        border-bottom: none;

                        td {
                            padding: 5px;
                            word-break: break-word;
                        }
                    }
                }
            }
        }   
    }
}
