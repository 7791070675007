/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable number-max-precision */
/* stylelint-disable font-family-no-missing-generic-family-keyword */

.MyaccountInstockData {
    &-Table {
        .MyaccountInstockDataTable {
            padding: 22px 23px;

            .Instock_table {
                width: 100%;
                border-bottom: 1px solid black;
                table-layout: auto;

                tr td {
                    // white-space: nowrap;
                    vertical-align: middle;

                    @include desktop {
                        padding-block: 20px;
                    }

                    @include tablet {
                        min-width: 60px;
                    }
                }

                .border-bottom {
                    border-bottom: 1px solid black;
                    font-size: 16px;
                }

                .table_data {
                    font-size: 14px;

                    @include desktop {
                        border-bottom: 0.5px solid #0f0f0f;
                    }

                    .unsunscribe_button {
                        color: var(--primary-base-color);
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;

                        button {
                            font-size: 14px;
                        }
                    }

                    .view_button {
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;

                        button {
                            font-size: 14px;
                            color: #0f0f0f;
                        }
                    }

                    .UPDATED {
                        color: #bb8376;

                        @include mobile {
                            color: #bb8376;
                        }
                    }

                    .SUBMITTED {
                        color: #00741b;

                        @include mobile {
                            color: #00741b;
                        }
                    }
                }
            }
        }

        @include mobile {
            margin-block-end: 5rem;
        }
    }

    &-products {
        @include desktop {
            display: inline-flex;
            column-gap: 12px;
        }

        @include tablet {
            display: grid;
            grid-gap: 10px;
        }

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &-label {
        @include mobile {
            color: #707070;
            font-size: 12px;
        }
    }

    &-productDate {
        display: flex;
    }

    &-mobileBorder {
        border-bottom: 1px solid #f2f2f2;
        margin-block-start: 16px;
    }

    &-productDetails {
        padding-inline: 15px;

        @include mobile {
            padding-block-end: 15px;
        }
    }

    &-Small_img {
        @include desktop {
            width: 104px;
            height: 104px;
        }

        @include mobile {
            width: 100%;
            margin: auto;
            padding-block-end: 60%;

            img {
                width: 100%;
                object-fit: contain;
                position: absolute;
                height: 100%;
            }
        }

        @include tablet {
            height: 118px;
        }
    }

    &-productName {
        color: #000000;
        font-family: Montserrat;
        font-weight: 600;
        width: 266px;
        text-overflow: ellipsis;
        height: 4.4rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 22px;
    }

    &-productCount {
        @include desktop {
            color: var(--primary-base-color);
        }

        @include mobile {
            color: #0f0f0f;
            font-size: 12px;
        }
    }

    &-productsDetails {
        display: grid;
        width: calc(100% - 116px);

        @include mobile {
            grid-gap: 10px;
        }
    }

    &-outOfStock {
        color: var(--primary-base-color);

        @include mobile {
            margin-inline-start: auto;
            font-size: 12px;
        }
    }

    &-inStock {
        color: #00741b;
    }

    &-productSubtotal {
        color: #707070;
    }

    &-productPrice {
        display: flex;
    }

    &-productTotPrice {
        margin-inline-start: 10px;

        @include mobile {
            color: #000000;
            font-weight: bold;
        }
    }

    &-productsSku {
        @include desktop {
            margin-block-start: 10px;
        }

        @include mobile {
            color: #707070;
            font-size: 12px;
        }
    }

    &-button {
        display: grid;

        @include desktop {
            grid-gap: 26px;
        }

        @include mobile {
            display: flex;
            margin-block-start: 16px;
            grid-gap: 16px;

            .unsunscribe_button {
                color: var(--primary-base-color);
                text-decoration: underline;
                text-underline-position: under;
                cursor: pointer;
                font-size: 12px;
            }

            .view_button {
                text-decoration: underline;
                text-underline-position: under;
                cursor: pointer;
                font-size: 12px;
                border-right: 1px solid #9d9d9d;
                padding-inline-end: 15px;
            }
        }
    }

    &-view_button {
        a {
            color: #0f0f0f;
            font-weight: 400;
            border-bottom: 1px solid;

            @include desktop {
                font-size: 14px;
            }

            @include mobile {
                font-size: 12px;
            }
        }

        @include mobile {
            margin-inline-start: auto;
        }
    }

    &-SearchOrderID {
        width: 100%;
        height: 123px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        padding-inline-start: 3%;

        @include mobile {
            height: 17rem;
        }

        .MyaccountInstockDataHeader {
            .QuotesHeader {
                text-align: start;
                font-size: 16px;
                letter-spacing: 0px;
                color: #0f0f0f;
                opacity: 1;
                text-transform: inherit;
                padding-block-start: 1rem;
            }
        }

        .SearchOrder {
            display: flex;
            gap: 2%;

            @include mobile {
                flex-direction: column;
                display: flex;
                row-gap: 1rem;
            }

            @include tablet {
                display: flex;
            }

            .MyaccountInstockDataSearch {
                .mySearch {
                    width: 500px;
                    height: 40px;
                    background: #fafafa 0% 0% no-repeat padding-box;
                    box-shadow: inset 1px 1px 6px #00000014;
                    border-radius: 4px;
                    opacity: 1;
                    font-size: 14px;

                    @include mobile {
                        width: 96%;
                        height: 36px;
                    }

                    @include tablet {
                        width: 100%;
                    }
                }
            }

            .MyaccountInstockDataButton {
                @include mobile {
                    display: flex;
                    justify-content: flex-end;
                }

                @include tablet {
                    display: flex;
                    justify-content: flex-end;
                }

                .SearchButton {
                    width: 178px;
                    height: 40px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #e53d2f;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #e53d2f;
                    text-transform: capitalize;
                    opacity: 1;
                    font-size: 14px;
                    letter-spacing: 0px;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                        background-color: #e53d2f;
                        color: #ffffff;
                    }

                    @include mobile {
                        width: 113px;
                        height: 36px;
                        background: var(--primary-base-color) 0% 0% no-repeat padding-box;
                        border-radius: 2px;
                        opacity: 1;
                        color: #ffffff;
                        font-size: 12px;
                        inset-inline-end: 1.5rem;

                        &:hover {
                            cursor: pointer;
                            background-color: #ffffff;
                            color: var(--primary-base-color);
                        }
                    }
                }
            }
        }
    }

    .quotestable {
        height: auto;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        inset-block-start: 3rem;

        @include mobile {
            margin-block-end: 30px;
        }
    }

    .Orders {
        &-Wrapper {
            background-color: #fafafa;
            margin-block-start: 12px;

            .seperator:not(:last-child) {
                flex: 1 0 auto;
                margin-block-start: 10px;
                height: 1px;
                background-color: #f2f2f2;
            }

            .Inner {
                padding: 12px;

                @include mobile {
                    background-color: white;
                    border-bottom: 1px solid #f2f2f2;
                }

                .OrderDate,
                .totalOrder,
                .shipTo,
                .status,
                .OrderNumber {
                    display: flex;
                    justify-content: space-between;
                    margin-block-end: 12px;
                    align-items: center;

                    span {
                        color: #707070;
                        font-size: 12px;
                    }

                    .bold {
                        font-weight: bold;
                        color: #000;
                        margin-inline-start: 12px;
                    }
                }

                .OrderNumber {
                    .seperator {
                        flex: 1 0 auto;
                        margin-inline-start: 10px;
                        height: 1px;
                        background-color: #707070;
                    }
                }

                .OrderStatus {
                    .status {
                        .done {
                            font-weight: bold;
                            color: #00741b;
                        }

                        .status_view {
                            inset-inline-end: 1.5rem;
                        }

                        .wait {
                            font-weight: bold;
                            color: #bb8376;
                        }
                    }
                }

                .OrderAction {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;
                    align-items: center;

                    .unsunscribe_button {
                        color: var(--primary-base-color);
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;
                        font-size: 12px;
                    }

                    .view_button {
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;
                        font-size: 12px;
                    }

                    .Vline {
                        height: 12px;
                        width: 1px;
                        background-color: #9d9d9d;
                    }
                }
            }
        }
    }

    &-table {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        margin-block-start: 2rem;

        .order_pagination {
            .orderd_list_item {
                inset-block-end: 3rem;
                inset-inline-start: 2rem;
                display: flex;
                gap: 1rem;
                font-size: 16px;
                color: #9d9d9d;

                p {
                    color: #9d9d9d;
                }
            }
        }
    }

    &-DateStock {
        display: flex;

        @include mobile {
            margin-block-start: 10px;
        }
    }

    &-date {
        margin-inline-start: 5px;
        font-size: 12px;
    }

    &-unsubscribe {
        inset-inline-end: 0;
        position: absolute;
        display: flex;
        padding: 0;
        padding-block-end: 12px;
        justify-content: flex-end;

        button {
            font-size: 16px;
            text-decoration: underline;
            color: var(--primary-base-color);
            inset-block-end: 5rem;
            margin-block-start: -15px;
            cursor: pointer;

            @include mobile {
                margin-block-start: 0px;
            }
        }
    }

    &-NoOrder {
        font-size: 16px;
        padding: 10px;
        background-color: #fff;
        color: #9d9d9d;
    }
}

table td,
table th {
    @include tablet {
        min-width: 20px;
        vertical-align: top;
    }
}

.orderd_list_item {
    font-style: italic;
}
