/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --secondary-base-color: #f5f5f5;
}

.LoginAccount {
    background-color: var(--secondary-base-color);

    .ContentWrapper {
        min-height: calc(100vh - var(--header-total-height));
        padding-block-start: 28px;
        padding-block-end: 135px;
        padding-inline: 20px;
        background-image: url('../../util/Images/signin.png');
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        background-size: 114% 70%;

        @include mobile() {
            background-size: 300% 85%;
            padding-block-start: 65px;
        }

        @include tablet() {
            background-size: 300% 100%;
        }

        @include desktop() {
            padding-block-start: 57px;
        }
    }

    &-CreateAccountButton {
        width: 100%;
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            padding-block-start: 0;
            grid-template-columns: 45% 55%;
        }

        @include tablet() {
            display: block;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-block: 20px;

            @include desktop {
                margin-block-start: 20px;
                border-radius: 4px;
            }
        }

        input {
            background-color: var(--seconday-base-color);
            box-shadow: inset 1px 1px 6px #00000014;
            border-radius: 4px;

            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 100%;
                height: 48px;
            }
        }

        > div {
            flex: 1;

            @include desktop() {
                margin: 0px;
            }

            @include tablet() {
                margin: 90px 90px;
            }
        }

        h4 {
            padding-block-end: 7px;
            text-align: center;
        }

        p {
            font-size: 16px;
            font-weight: bolder;
            margin-block-start: 10px;
            margin-block-end: 0px;
            display: flex;
            justify-content: center
        }
    }

    &-createAccountMsg {
        background: #fff;
        padding: 5px;
        margin-block-end: 20px;
        text-align: center;

        h2 {
            margin-block: 5px;
        }
    }

    &-SignInWrapper {
        background-color: #fff;
        width: 95%;
        padding: 2rem 14rem;
        border-radius: 4px;
        
        @include desktop {
            box-shadow: 5px 5px 15px #0000000d;
        }
        
        @include mobile() {
            width: 100%;
            padding: 10px 30px;
        }

        @include tablet() {
            width: 80%;
            padding: 10px 30px;
        }

        h2 {
            display: flex;
            justify-content: center;
        }

        .ForgotPasswordLink {
            display: flex;
            justify-content: flex-end;
        }
    }

    .Loader {
        margin: 0;
    }

    &-CreateAccountWrapper {
        margin-block-start: 24px;
        display: flex;
        align-items: center;

        h4 {
            margin-block-end: 0;
        }

        button {
            width: auto;
            margin-inline-start: 5px;
        }
    }

    .button_create_account {
        width: 100%;
        padding: 16px;
        border: 1px solid #BA8377;
        color: #BA8377;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;

        &:hover {
            background-color: #BA8377;
            color: #fff;
            text-decoration: none;
        }
    }

    .Footer {
        @include mobile {
            padding-block-start: 72px;
        }
    }
}
